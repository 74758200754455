<template>
    <div>
        <b-row>
            <b-col cols="12">
                <b-form-group class="mb-0">
                    <b-button variant="primary" @click="getReport">
                        {{ $t('get_report').toUpper() }}
                    </b-button>
                </b-form-group>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import LecturerReportService from "@/services/LecturerReportService"

export default {
    methods: {
        getReport() {
            LecturerReportService.getCourseListReport()
                .then((response) => {
                    if(response?.data?.data) {
                        const headers = [
                            { header: this.$t('course_code'), key:"course_code", width: 15 },
                            { header: this.$t('isThereExamSchedule'), key:"exam_schedule_exists", width: 24, style: { alignment : { horizontal: 'left' }} }
                        ]
                        const formatting = {
                            'exam_schedule_exists' : (value)=> value ? this.$t('yes') : this.$t('no')
                            }
                        this._downloadExcelFile({ data: response.data.data, filename: this.$t('exam_schedule_report') + '.xlsx', headers, formatting})
                        return
                    }
                    this.$toast.error(this.$t('no_result'));
                })
                
        }
    }
}
</script>

<template>
    <div>
        <ValidationObserver ref="filterForm">
            <b-row>
                <b-col cols="12" md="6">
                    <ValidationProvider name="semester" rules="required" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('semester')">
                            <semesters-selectbox
                                v-model="filter.semester_id" :validate-error="errors[0]"
                            ></semesters-selectbox>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12" md="6">
                    <b-form-group :label="$t('faculty')">
                        <faculty-selectbox-multiple v-model="filter.faculty_code"></faculty-selectbox-multiple>
                    </b-form-group>
                </b-col>
                <b-col cols="12" md="6">
                    <b-form-group :label="$t('department')">
                        <department-selectbox-multiple v-model="filter.department_code" :faculty_code="filter.faculty_code"></department-selectbox-multiple>
                    </b-form-group>
                </b-col>

                <b-col cols="12" md="6">
                    <b-form-group :label="$t('report_type')">
                        <multi-selectbox
                            :allow-empty="false" :multiple="false"
                            :options="responseTypeOptions" v-model="responseType"
                        ></multi-selectbox>
                    </b-form-group>
                </b-col>
                <b-col cols="12">
                    <b-button variant="primary" @click="getReport">
                        {{ $t('get_report').toUpper() }}
                    </b-button>
                </b-col>
            </b-row>
        </ValidationObserver>
    </div>
</template>

<script>
import SemestersSelectbox from '@/components/interactive-fields/SemestersSelectbox'
import MultiSelectbox from '@/components/interactive-fields/MultiSelectbox'
import LecturerReportService from '@/services/LecturerReportService'
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import qs from 'qs'
import FacultySelectboxMultiple from "@/components/interactive-fields/FacultySelectboxMultiple.vue";
import DepartmentSelectboxMultiple from "@/components/interactive-fields/DepartmentSelectboxMultiple.vue";

export default {
    components: {
        DepartmentSelectboxMultiple,
        FacultySelectboxMultiple,
        ValidationObserver,
        ValidationProvider,
        SemestersSelectbox,
        MultiSelectbox
    },
    data() {
        return {
            filter: {
                response_type: 'excel'
            },
            responseType: 'page',
            responseTypeOptions: [
                {value: 'page', text: this.$t('page')},
                {value: 'excel', text: this.$t('excel')},
                {value: 'pdf', text: 'PDF'}
            ]
        }
    },
    methods: {
        async getReport() {
            const isValid = await this.$refs.filterForm.validate()

            if (isValid) {
                let config = {
                    params: {
                        filter: {
                            ...this.filter
                        },
                        response_type: this.responseType
                    },
                    paramsSerializer: (params) => qs.stringify(params, {encode: false})
                }

                if(this.responseType != 'page'){
                    config.responseType='arraybuffer';
                }

                LecturerReportService.getGradeEntryReportByFacultyCourse(config)
                    .then((response) => {
                        if(this.responseType == 'page') {
                            this._openFile(response)
                        }
                        else if(this.responseType == 'excel') {
                            this._downloadFile(response, 'course_based_grade_entry_report.xlsx')
                        }
                        else if(this.responseType == 'pdf') {
                            this._downloadFile(response, 'course_based_grade_entry_report.pdf')
                        }
                    })
                    .catch((error) => {
                        this.showErrors(error)
                    })
            }
        }
    }
}
</script>

// Plugins
import i18n from '@/plugins/i18n'

export default function() {
    let reportTypes = []

    // Student Reports
    reportTypes.push({
        value: 'student-report',
        text: i18n.t('student_report'),
        permission: 'studentslot_studentslotsexcelexport'
    })


    // Educator Reports
    reportTypes.push({
        value: 'educator-report',
        text: i18n.t('educator_report'),
        permission: 'section_instructorlecturereportexcelexport'
    })

    // Faculty Based Grade Entry Reports
    reportTypes.push({
        value: 'faculty-grade-entry-report',
        text: i18n.t('faculty_grade_entry_report'),
        permission: 'faculty_gradeentryreport'
    })

    // Course Based Grade Entry Reports
    reportTypes.push({
        value: 'course-grade-entry-report',
        text: i18n.t('course_grade_entry_report'),
        permission: 'faculty_gradeentrybycoursereport'
    })

    // Faculty Course Based Grade Entry Reports
    reportTypes.push({
        value: 'faculty-course-grade-entry-report',
        text: i18n.t('faculty_course_grade_entry_report'),
        permission: 'faculty_facultycoursegradeentryreport'
    })

    // Survey Reports
    reportTypes.push({
        value: 'survey-report',
        text: i18n.t('survey_report'),
        //permission: 'studentreport_registrationnumbersbyfaculty'
    })

    // Exam Schedule
    reportTypes.push({
        value: 'exam-schedule-report',
        text: i18n.t('exam_schedule_report'),
        permission: 'section_instructorlecturereportexcelexport'
    })

    return reportTypes
}



<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('lecturer_report')" :isFilter="false" />
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile :title="$t('lecturer_report')" :isFilter="false" />
            </template>
            <ValidationObserver ref="form">
                <div class="row">
                    <div class="col-12">
                        <ValidationProvider name="report" rules="required" v-slot="{valid, errors}">
                            <h6>{{ $t('select_report_type') }}</h6>
                            <b-form-group>
                                <multiselect
                                    :multiple="false" :validate-error="errors[0]"
                                    :options="reportOptions" v-model="reportType"
                                    label="text" track-by="value"
                                    :select-label="''" :selected-label="''"
                                    :clear-on-select="true" :close-on-select="true"
                                    :deselect-label="''" :placeholder="$t('select')"
                                />
                            </b-form-group>
                        </ValidationProvider>
                    </div>
                    <div class="col-12" v-if="reportType">
                        <h6>
                            <span v-if="reportType && reportType.text">
                                {{ reportType.text }}
                            </span>
                        </h6>
                        <div class="border rounded p-5">
                            <component v-if="reportType && reportType.value" :is="reportType.value"/>
                        </div>
                    </div>
                </div>
            </ValidationObserver>
        </app-layout>
    </div>
</template>

<script>
// Template
import AppLayout from '@/layouts/AppLayout'
import Header from '@/layouts/AppLayout/Header'
import HeaderMobile from '@/layouts/AppLayout/HeaderMobile'
// Pages
import StudentReport from './studentReport/Index'
import EducatorReport from './educatorReport/Index'
import FacultyGradeEntryReport from './facultyGradeEntryReport/Index'
import CourseGradeEntryReport from './courseGradeEntryReport/Index'
import examScheduleReport from './examScheduleReport/Index'
import facultyCourseGradeEntryReport from './facultyCourseGradeEntryReport/Index'
// Data
import ReportTypes from '../data/ReportTypes'
// Other
import {ValidationObserver, ValidationProvider} from 'vee-validate'

export default {
    components: {
        AppLayout,
        Header,
        HeaderMobile,
        StudentReport,
        EducatorReport,
        FacultyGradeEntryReport,
        CourseGradeEntryReport,
        examScheduleReport,
        ValidationObserver,
        ValidationProvider,
        facultyCourseGradeEntryReport
    },
    computed: {
        reportOptions() {
            return ReportTypes().filter((item) => {
                return this.checkPermission(item.permission)
            })
        }
    },
    data() {
        return {
            reportType: null
        }
    },
    metaInfo() {
        return {
            title: this.$t('lecturer_report')
        }
    }
}
</script>
